import {
  ADDING_DAILY_CHECK_IN_STARTED,
  ADDING_DAILY_CHECK_IN_SUCCESS,
  ADDING_DAILY_CHECK_IN_FAILED,
  FETCH_DAILY_CHECK_IN_STARTED,
  FETCH_DAILY_CHECK_IN_SUCCESS,
  FETCH_DAILY_CHECK_IN_FAILED,
  ADD_CHECK_IN_MODAL_OPEN,
  ADD_CHECK_IN_MODAL_CLOSE,
} from '../constants/checkIns';

const initialState = {
  loading: true,
  missing: [],
  completed: [],
  error: null,
  checkInModalOpen: false,
}

const checkIns = (state = initialState, action) => {
  switch(action.type) {
    case ADDING_DAILY_CHECK_IN_STARTED:
      return { ...state, loading: true };
    case ADDING_DAILY_CHECK_IN_SUCCESS:
      return { ...state, loading: false };
    case ADDING_DAILY_CHECK_IN_FAILED:
      return { ...state, loading: false };
    case FETCH_DAILY_CHECK_IN_STARTED:
      return { ...state, loading: true, error: null };
    case FETCH_DAILY_CHECK_IN_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case FETCH_DAILY_CHECK_IN_FAILED:
      return { ...state, loading: false, error: action.payload };
    case ADD_CHECK_IN_MODAL_OPEN:
      return { ...state, checkInModalOpen: true };
    case ADD_CHECK_IN_MODAL_CLOSE:
      return { ...state, checkInModalOpen: false };
    default:
      return state;
  }
}

export default checkIns;
