import request from "../../config/request";

import {
  ADDING_DAILY_CHECK_IN_STARTED,
  ADDING_DAILY_CHECK_IN_SUCCESS,
  ADDING_DAILY_CHECK_IN_FAILED,
  FETCH_DAILY_CHECK_IN_STARTED,
  FETCH_DAILY_CHECK_IN_SUCCESS,
  FETCH_DAILY_CHECK_IN_FAILED,
  ADD_CHECK_IN_MODAL_OPEN,
  ADD_CHECK_IN_MODAL_CLOSE,
} from '../constants/checkIns';

export const addDailyCheckIn = (clinicId, data) => {
  return dispatch => {
    dispatch({ type: ADDING_DAILY_CHECK_IN_STARTED });
    request.post(`/api/clinics/${clinicId}/daily_check_ins`, data).then(response => {
      dispatch({ type: ADDING_DAILY_CHECK_IN_SUCCESS, payload: response.data });
      dispatch({ type: ADD_CHECK_IN_MODAL_CLOSE });
      dispatch(fetchDailyCheckIns(clinicId));
    }).catch(error => {
      console.error(error);
      dispatch({ type: ADDING_DAILY_CHECK_IN_FAILED, payload: error.response?.data });
    });
  }
}

export const fetchDailyCheckIns = (clinicId) => {
  return dispatch => {
    dispatch({ type: FETCH_DAILY_CHECK_IN_STARTED });
    request.get(`/api/clinics/${clinicId}/daily_check_ins`).then(response => {
      dispatch({ type: FETCH_DAILY_CHECK_IN_SUCCESS, payload: response.data });
    }).catch(error => {
      console.error(error);
      dispatch({ type: FETCH_DAILY_CHECK_IN_FAILED, payload: error.response?.data });
    });
  }
}

export const setCheckInModalOpen = (isOpen) => {
  return dispatch => {
    dispatch({type: isOpen ? ADD_CHECK_IN_MODAL_OPEN : ADD_CHECK_IN_MODAL_CLOSE });
  }
}