const ADDING_DAILY_CHECK_IN_STARTED = 'ADDING_DAILY_CHECK_IN_STARTED';
const ADDING_DAILY_CHECK_IN_SUCCESS = 'ADDING_DAILY_CHECK_IN_SUCCESS';
const ADDING_DAILY_CHECK_IN_FAILED = 'ADDING_DAILY_CHECK_IN_FAILED';
const FETCH_DAILY_CHECK_IN_STARTED = 'FETCH_DAILY_CHECK_IN_STARTED';
const FETCH_DAILY_CHECK_IN_SUCCESS = 'FETCH_DAILY_CHECK_IN_SUCCESS';
const FETCH_DAILY_CHECK_IN_FAILED = 'FETCH_DAILY_CHECK_IN_FAILED';
const ADD_CHECK_IN_MODAL_OPEN = 'ADD_CHECK_IN_MODAL_OPEN';
const ADD_CHECK_IN_MODAL_CLOSE = 'ADD_CHECK_IN_MODAL_CLOSE';


export {
  ADDING_DAILY_CHECK_IN_STARTED,
  ADDING_DAILY_CHECK_IN_SUCCESS,
  ADDING_DAILY_CHECK_IN_FAILED,
  FETCH_DAILY_CHECK_IN_STARTED,
  FETCH_DAILY_CHECK_IN_SUCCESS,
  FETCH_DAILY_CHECK_IN_FAILED,
  ADD_CHECK_IN_MODAL_OPEN,
  ADD_CHECK_IN_MODAL_CLOSE,
}