import React from "react";
import {Button, Card, Col, Popconfirm, Row, Space, Statistic} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AiOutlineSchedule, BiDollarCircle, BsEye, BsHandThumbsDown, IoIosWalk, MdCelebration} from "react-icons/all";

import CardTitle from "../../components/card-title/CardTitle";


const AddMetricButton = ({ onAdd, onMarkAsHoliday }) => (
  <Space>
    <Button type="primary" icon={<PlusOutlined />} onClick={onAdd}>Add</Button>
    <Popconfirm title="Are you sure to mark this day as holiday?" onConfirm={onMarkAsHoliday} okText="Yes" cancelText="No">
      <Button className="mark-as-holiday" icon={<MdCelebration size={16}/>}>Mark As Holiday</Button>
    </Popconfirm>
  </Space>
)

const MissingMetric = ({ date, onAdd, onMarkAsHoliday }) => {
  return (
    <Card className="missing-metric metric" title={<CardTitle date={date} />} extra={<AddMetricButton onAdd={onAdd} onMarkAsHoliday={onMarkAsHoliday} />}>
      <Row justify="space-between">
        <Col className="item">
          <Statistic title="Seen" value="--" prefix={<BsEye size={16} />} />
        </Col>
        <Col className="item">
          <Statistic title="Scheduled" value="--" prefix={<AiOutlineSchedule size={16} />} />
        </Col>
        <Col className="item">
          <Statistic title="No Shows" value="--" prefix={<BsHandThumbsDown size={18} />} />
        </Col>
        <Col className="item">
          <Statistic title="New Patients" value="--" prefix={<IoIosWalk size={16} />} />
        </Col>
        <Col className="item">
          <Statistic title="Hygiene Production" value="--" prefix={<BiDollarCircle size={18} />} />
        </Col>
        <Col className="item">
          <Statistic title="Dentist Production" value="--" prefix={<BiDollarCircle size={18} />} />
        </Col>
        <Col className="item">
          <Statistic title="Adjustments" value="--" prefix={<BiDollarCircle size={18} />} />
        </Col>
      </Row>
      <Row className="comments">
        <Col>
          <Statistic title="Comments" value="--" />
        </Col>
      </Row>
    </Card>
  )
}

export default MissingMetric;