import React from "react";
import { connect } from "react-redux";
import {Button, Form,  Input} from "antd";
import { UserOutlined, LockOutlined, LockTwoTone } from "@ant-design/icons";
import {login} from "../../redux/actions/users";

import "./Login.less";

const Login = ({ loading, onSubmit }) => {

  const onFinish = (data) => {
    onSubmit(data);
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="login-header">
          <LockTwoTone style={{ fontSize: 24 }}/>
          <h1 className="login-title">Sign In</h1>
        </div>
        <Form onFinish={onFinish} className="signin-form">
          <Form.Item rules={[{ required: true, message: 'Please input your E-mail!' }]} name={['user', 'email']}>
            <Input prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Email" size="large" />
          </Form.Item>
          <Form.Item rules= {[{required: true, message: 'Please input your Password!'}]}  name={['user', 'password']}>
            <Input prefix={<LockOutlined  style={{color: 'rgba(0,0,0,.25)'}}/>} type="password" placeholder="Password" size="large" />
          </Form.Item>
          <Form.Item className="signin-form-button">
            <Button type="primary" htmlType="submit" size="large" disabled={loading} loading={loading}>Log in</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ...state.users };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => {
      dispatch(login(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
