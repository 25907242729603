import React, { useState } from "react";
import {Button, Col, DatePicker, Row, Space} from "antd";

import moment from 'moment'
import {BiExport} from "react-icons/all";

moment.updateLocale('en', {
  weekdaysMin : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
});

const { RangePicker } = DatePicker;

//TODO: maxRange: This value should be -1 of what is desired
const RangeFilter = ({ onChange, rangeType, maxRange }) => {

  const [dates, setDates] = useState([]);
  const [value, setValue] = useState([]);

  const handleChange = (val, datesString) => {
    setValue(val);
    onChange(datesString);
  }

  const handleCalendarChange = (val) => {
    setDates(val);
  }

  const disabledDate = current => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const unitTime = rangeType === 'date' ? 'days' : rangeType;
    const tooLate = dates[0] &&  current.diff(dates[0], unitTime, true) > maxRange;
    const tooEarly = dates[1] && dates[1].diff(current, unitTime, true) > maxRange;
    return tooEarly || tooLate;
  };

  return (
    <RangePicker
      value={value}
      disabledDate={disabledDate}
      picker={rangeType}
      onChange={handleChange}
      onCalendarChange={handleCalendarChange}
    />
  )
}

const DateFilter = ({ group, exportEnabled, onChange, onExport }) => {
  return (
    <Row justify="end" className="date-filter">
      <Col>
        <Space>
          <Button disabled={!exportEnabled} onClick={onExport}>
            <BiExport size="1.2em"/>
          </Button>
          {group === 'year' && <RangeFilter rangeType="year" maxRange={4} onChange={onChange} />}
          {group === 'month' && <RangeFilter rangeType="month" maxRange={5} onChange={onChange} />}
          {group === 'week' && <RangeFilter rangeType="week" maxRange={25} onChange={onChange} />}
          {group === 'date' && <RangeFilter rangeType="date" maxRange={89} onChange={onChange} />}
        </Space>
      </Col>
    </Row>
  )
}

export default DateFilter;