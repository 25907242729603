import axios from 'axios';
import {notification} from "antd";
import history from './history';

const request = axios.create();

export const getUserToken = () =>  localStorage.getItem("token") || '';

// Add a request interceptor
request.interceptors.request.use(function (config) {
  config.headers['Authorization'] = getUserToken();
  return config;
}, function (error) {
  return Promise.reject(error);
});

request.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const { status } = error.response;
  if (status === 401) {
    localStorage.removeItem('token');
    notification.error({ message: "Session Expired!", description: "Please login again." });
    history.push('/login');
  }
  return Promise.reject(error);
});

export default request;