import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import users from './users';
import viewMetrics from "./viewMetrics";
import addMetrics from "./addMetrics";
import clinics from "./clinics";
import checkIns from "./checkIns";

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  users,
  viewMetrics,
  addMetrics,
  clinics,
  checkIns,
});

export default createRootReducer;
