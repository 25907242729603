import React  from "react";
import {Button, Form, Input, Modal} from "antd";
import {AiOutlineSchedule, BiDollarCircle, BsEye, BsHandThumbsDown, IoIosWalk} from "react-icons/all";
import moment from "moment";


const AddMetricDialog = ({ date, isOpen, onSubmit, onClose }) => {

  const handleSubmit = (data) => {
    onSubmit(data);
  }

  return (
    <Modal title={`Add Metrics for ${moment(date).format('dddd MMMM D, YYYY')}`} visible={isOpen} footer={null} onCancel={onClose} destroyOnClose>
      <Form onFinish={handleSubmit} className="signin-form" layout="vertical">
        <Form.Item rules={[{required: true, message: 'Please enter patients seen!'}]} name={['daily_report', 'seen']}>
          <Input prefix={<BsEye style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Patients Seen" size="large" type="number" autoFocus />
        </Form.Item>
        <Form.Item rules={[{required: true, message: 'Please enter appointments scheduled!'}]} name={['daily_report', 'scheduled']}>
          <Input prefix={<AiOutlineSchedule style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Appointments Scheduled" size="large" type="number" />
        </Form.Item>
        <Form.Item rules={[{required: true, message: 'Please enter no-show patients!'}]} name={['daily_report', 'no_shows']}>
          <Input prefix={<BsHandThumbsDown style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="No Show Patients" size="large"/>
        </Form.Item>
        <Form.Item rules={[{required: true, message: 'Please enter new patients!'}]} name={['daily_report', 'walk_ins']}>
          <Input prefix={<IoIosWalk style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="New Patients" size="large"/>
        </Form.Item>
        <Form.Item rules={[{required: true, message: 'Please enter hygiene production amount!'}]} name={['daily_report', 'hygienists_production']}>
          <Input prefix={<BiDollarCircle style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Hygiene Production" size="large"/>
        </Form.Item>
        <Form.Item rules={[{required: true, message: 'Please enter dentist production amount!'}]} name={['daily_report', 'dentists_production']}>
          <Input prefix={<BiDollarCircle style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Dentist Production" size="large"/>
        </Form.Item>
        <Form.Item name={['daily_report', 'adjustment_production']}>
          <Input prefix={<BiDollarCircle style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Adjustments" size="large"/>
        </Form.Item>
        <Form.Item name={['daily_report', 'comments']}>
          <Input.TextArea placeholder="Comments" size="large" maxLength={250} />
        </Form.Item>
        <Form.Item className="signin-form-button">
          <Button type="primary" htmlType="submit" size="large">Add Metrics</Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddMetricDialog;