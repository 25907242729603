import React from 'react';
import { Route, Switch } from 'react-router-dom';

import history from '../config/history';
import StackLayout from "../layouts/stack-layout";
import Login from '../pages/users/Login';
import {ConnectedRouter} from "connected-react-router";
import Dashboard from "../pages/dashboard";
import CheckIn from "../pages/check-in";
import AddMetrics from "../pages/add-metrics";
import ViewMetrics from "../pages/view-metrics/ViewMetrics";


const RouteWithLayout = ({ component: Component, layout: Layout, title, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout title={title}>
      <Component {...props} />
    </Layout>
  )} />
);

const Routes = () => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <RouteWithLayout exact path="/login" component={Login} layout={StackLayout} />
        <RouteWithLayout exact path="/dashboard" component={Dashboard} layout={StackLayout} />
        <RouteWithLayout exact path="/check-in" component={CheckIn} layout={StackLayout} />
        <RouteWithLayout exact path="/add-metrics" component={AddMetrics} layout={StackLayout} />
        <RouteWithLayout exact path="/view-metrics" component={ViewMetrics} layout={StackLayout} />
        <RouteWithLayout exact path="/" component={Dashboard} layout={StackLayout} />
      </Switch>
    </ConnectedRouter>
  )
};


export default Routes;