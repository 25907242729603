import {
  FETCHING_CLINICS,
  FETCH_CLINICS_SUCCESS,
  FETCH_CLINICS_FAILED,
} from '../constants/clinics';

const initialState = {
  loading: true,
  clinics: [],
  regions: [],
  error: null,
}

const clinics = (state = initialState, action) => {
  switch(action.type) {
    case FETCHING_CLINICS:
      return { ...state, loading: true, clinics: [], regions: [], error: null };
    case FETCH_CLINICS_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case FETCH_CLINICS_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default clinics;
