import React from "react";
import { connect } from "react-redux";
import {Button, Form} from "antd";

import history from "../../config/history";
import "./Dashboard.less";


const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="dashboard-header">
          <h1 className="dashboard-title">Select Action</h1>
        </div>
        <Form className="dashboard-form">
          <Form.Item>
            <Button type="primary" size="large" onClick={() => history.push("check-in")}>Check In</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" size="large" onClick={() => history.push("add-metrics")}>Add Metrics</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" size="large" onClick={() => history.push("view-metrics")}>View Metrics</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ...state.users };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => {

    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
