import {
  FETCHING_METRICS,
  FETCH_METRICS_SUCCESS,
  FETCH_METRICS_FAILED,
} from '../constants/viewMetrics';

const initialState = {
  loading: true,
  data: [],
  error: null,
}

const viewMetrics = (state = initialState, action) => {
  switch(action.type) {
    case FETCHING_METRICS:
      return { ...state, loading: true, data: [], error: null };
    case FETCH_METRICS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_METRICS_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default viewMetrics;
