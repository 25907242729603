import React from "react";
import moment from "moment";
import { Button, Form, Input, Modal } from "antd";
import { MdOutlinePersonalInjury, RiStethoscopeFill, FaTeethOpen } from "react-icons/all";



const CheckInDialog = ({ date, isOpen, onSubmit, onClose }) => {

  const handleSubmit = (data) => {
    onSubmit(data);
  }

  return (
    <Modal title={`Check-In for ${moment(date).format('dddd MMMM D, YYYY')}`} visible={isOpen} footer={null} onCancel={onClose} destroyOnClose>
      <Form onFinish={handleSubmit} className="signin-form" layout="vertical">
        <Form.Item rules={[{required: true, message: 'Please enter no. of dentists!'}]} name={['daily_check_in', 'no_of_dentists']}>
          <Input prefix={<RiStethoscopeFill style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="No. of Dentists" size="large" type="number" autoFocus />
        </Form.Item>
        <Form.Item rules={[{required: true, message: 'Please enter no. of hygienists!'}]} name={['daily_check_in', 'no_of_hygienists']}>
          <Input prefix={<FaTeethOpen style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="No. of Hygienists" size="large" type="number" />
        </Form.Item>
        <Form.Item rules={[{required: true, message: 'Please enter no. of office team members!'}]} name={['daily_check_in', 'no_of_office_team_members']}>
          <Input prefix={<MdOutlinePersonalInjury style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="No. of Office Team Members" size="large" type="number" />
        </Form.Item>
        <Form.Item className="signin-form-button">
          <Button type="primary" htmlType="submit" size="large">Check-In</Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CheckInDialog;