import React, { useEffect } from 'react';
import {connect} from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {Layout, Image, Avatar, Menu, Dropdown, notification} from 'antd';
import {UserOutlined} from "@ant-design/icons";
import { logout } from "../../redux/actions/users";
import history from "../../config/history";

import './StackLayout.less';
import logo from "../../static/logo.png";

const { Header, Content } = Layout;

const Menus = ({ logout }) => {
  const handleMenuClick = ({ key }) => {
    if(key === 'logout'){ logout() }
  };
  return (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="logout">Logout</Menu.Item>
    </Menu>
  );
}

const SideLayoutComponent = ({ logout, token, children, location }) => {

  useEffect(() => {
    if(!token && location.pathname !== '/login') {
      notification.warn({message: "Please login to access that page.", duration: 10});
      history.push('/login');
    }
  }, [location]);

  const loggedIn = !!token && location.pathname !== '/login';
  return (
    <Layout className="stack-layout">
      <Header className="stack-layout-header">
        <Link to="/dashboard">
          <Image width={125} src={logo}  className="logo" alt="Company Logo" preview={false} />
        </Link>
        <Dropdown overlay={<Menus logout={logout} />}>
          <Avatar size="large" className={`stack-layout-avatar-right stack-layout-avatar-${loggedIn ? 'show' : 'hide'}`} icon={<UserOutlined />} />
        </Dropdown>
      </Header>
      <Content className="stack-layout-content">
        <div className="stack-layout-main-content">
          {children}
        </div>
      </Content>
    </Layout>
  );
}


const mapStateToProps = (state) => {
  return { ...state.users };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(logout());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideLayoutComponent));