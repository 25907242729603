import {
  USER_LOGIN_STARTED,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  USER_LOGOUT_STARTED,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILED,
} from '../constants/users';


const initialState = {
  loading: false,
  user: JSON.parse(localStorage.getItem('user') || '{}'),
  token: localStorage.getItem('token') || null,
  error: null,
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_STARTED:
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      return { ...state, loading: true, user: {}, error: null };
    case USER_LOGIN_SUCCESS:
      const { user, token } = action.payload;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      return { ...state, loading: false, user, token, error: null };
    case USER_LOGIN_FAILED:
      return { ...state, loading: false, error: action.payload, user: {} };
    case USER_LOGOUT_STARTED:
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      return { ...state, loading: false, user: {}, token: null, error: null };
    case USER_LOGOUT_SUCCESS:
      return { ...state, loading: false, user: {}, error: null };
    case USER_LOGOUT_FAILED:
      return { ...state, loading: false, user: {}, error: null };
    default:
      return state
  }
};

export default users;
