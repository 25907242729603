import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {Empty, List, Typography} from "antd";
import {If, Then} from "react-if";

import Navigation from "./Navigation";
import CheckInDialog from "./CheckInDialog";
import MissingMetric from "./MissingMetric";
import CompletedMetric from "./CompletedMetric";

import {fetchClinics} from "../../redux/actions/clinics";
import {addDailyCheckIn, fetchDailyCheckIns, setCheckInModalOpen} from "../../redux/actions/checkIns";

import "./CheckIn.less";

const CheckIn = ({ user, addDailyCheckIn, fetchDailyCheckIns, fetchClinics, clinics, missing, completed, checkInModalOpen, setCheckInModalOpen }) => {

  const [clinicId, setClinicId] = useState(null);
  const [currentCheckInDate, setCurrentCheckInDate] = useState(null);
  const [view, setView] = useState("All");

  const handleCheckIn = (date) => {
    setCheckInModalOpen(true);
    setCurrentCheckInDate(date);
  }

  const handleMarkAsHoliday = (date) => {
    const data = {
      daily_check_in: {
        check_in_date: date,
        holiday: true
      }
    }
    addDailyCheckIn(clinicId, data);
  }

  const handleClose = () => {
    setCheckInModalOpen(false);
  }

  const handleSubmit = (data) => {
    data['daily_check_in']['check_in_date'] = currentCheckInDate;
    addDailyCheckIn(clinicId, data);
  }

  const handleClinicChange = (clinicId) => {
    if(clinicId) {
      fetchDailyCheckIns(clinicId);
    }
    setClinicId(clinicId);
  }

  const handleViewChange = (view) => {
    setView(view);
  }

  useEffect(() => {
    fetchClinics();
  }, []);

  return (
    <div className="check-in-container">
      <Navigation user={user} clinics={clinics} onClinicChange={handleClinicChange} missingCount={missing.length} completedCount={completed.length} onViewChange={handleViewChange} />
      <CheckInDialog date={currentCheckInDate} isOpen={checkInModalOpen} onClose={handleClose} onSubmit={handleSubmit} />
      <If condition={clinicId && (view === 'All' || view === 'Missing')}>
        <Then>
          <Typography.Title level={4}>Missing Check-In</Typography.Title>
          <List dataSource={missing} renderItem={(date) => (
            <MissingMetric key={date} date={date} onAdd={() => handleCheckIn(date)} onMarkAsHoliday={() => handleMarkAsHoliday(date)}/>
          )} />
        </Then>
      </If>
      <If condition={clinicId && (view === 'All' || view === 'Completed')}>
        <Then>
          <Typography.Title level={4}>Completed Check-In</Typography.Title>
          <List dataSource={completed} renderItem={(data) => (
            <CompletedMetric key={data.check_in_date} data={data} />
          )} />
        </Then>
      </If>
      <If condition={!clinicId}>
        <Then>
          <Empty description="Please select a clinic from the dropdown"/>
        </Then>
      </If>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ...state.clinics, ...state.checkIns, ...state.users };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDailyCheckIn: (clinicId, data) => {
      dispatch(addDailyCheckIn(clinicId, data));
    },
    fetchDailyCheckIns: (clinicId) => {
      dispatch(fetchDailyCheckIns(clinicId));
    },
    fetchClinics: () => {
      dispatch(fetchClinics());
    },
    setCheckInModalOpen: (isOpen) => {
      dispatch(setCheckInModalOpen(isOpen))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);
