import React from 'react';
import { IconContext } from "react-icons";

import Routes from './config/Routes';

import 'antd/dist/antd.less';
import './App.less';


function App() {
  return (
    <div className="App">
      <IconContext.Provider value={{ color: "#72CEC1" }}>
        <Routes />
      </IconContext.Provider>
    </div>
  );
}

export default App;
