import {
  ADDING_DAILY_REPORT_STARTED,
  ADDING_DAILY_REPORT_SUCCESS,
  ADDING_DAILY_REPORT_FAILED,
  FETCH_DAILY_REPORT_STARTED,
  FETCH_DAILY_REPORT_SUCCESS,
  FETCH_DAILY_REPORT_FAILED,
  ADD_METRIC_MODAL_OPEN,
  ADD_METRIC_MODAL_CLOSE,
} from '../constants/addMetrics';

const initialState = {
  loading: true,
  missing: [],
  completed: [],
  error: null,
  addMetricModalOpen: false,
}

const addMetrics = (state = initialState, action) => {
  switch(action.type) {
    case ADDING_DAILY_REPORT_STARTED:
      return { ...state, loading: true };
    case ADDING_DAILY_REPORT_SUCCESS:
      return { ...state, loading: false };
    case ADDING_DAILY_REPORT_FAILED:
      return { ...state, loading: false };
    case FETCH_DAILY_REPORT_STARTED:
      return { ...state, loading: true, error: null };
    case FETCH_DAILY_REPORT_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case FETCH_DAILY_REPORT_FAILED:
      return { ...state, loading: false, error: action.payload };
    case ADD_METRIC_MODAL_OPEN:
      return { ...state, addMetricModalOpen: true };
    case ADD_METRIC_MODAL_CLOSE:
      return { ...state, addMetricModalOpen: false };
    default:
      return state;
  }
}

export default addMetrics;
