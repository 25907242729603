import React from "react";
import {BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";
import {Typography} from "antd";

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const MetricsChart = ({ data }) => {

  return (
    <div className="metrics-chart">
      <Typography.Title level={4}>Patient's Report - Seen</Typography.Title>
      <BarChart width={1040} height={300} data={data} margin={{ top: 5, right: 25, bottom: 5, left: 0 }}>
        <Bar dataKey="seen" fill="#5E1AA1" />
        <CartesianGrid stroke="#CCC5" strokeDasharray="2 2" />
        <XAxis dataKey="report_unit" />
        <YAxis />
        <Tooltip cursor={{fill: '#4749A022'}} />
      </BarChart>
      <Typography.Title level={4}>Patient's Report - Scheduled</Typography.Title>
      <BarChart width={1040} height={300} data={data} margin={{ top: 5, right: 25, bottom: 5, left: 0 }}>
        <Bar dataKey="scheduled" fill="#A11D1A" />
        <CartesianGrid stroke="#CCC5" strokeDasharray="2 2" />
        <XAxis dataKey="report_unit" />
        <YAxis />
        <Tooltip cursor={{fill: '#4749A022'}} />
      </BarChart>
      <Typography.Title level={4}>Patient's Report - New Patients</Typography.Title>
      <BarChart width={1040} height={300} data={data} margin={{ top: 5, right: 25, bottom: 5, left: 0 }}>
        <Bar dataKey="walk_ins" fill="#2696ED" />
        <CartesianGrid stroke="#CCC5" strokeDasharray="2 2" />
        <XAxis dataKey="report_unit" />
        <YAxis />
        <Tooltip cursor={{fill: '#4749A022'}} />
      </BarChart>
      <Typography.Title level={4}>Patient's Report - No Shows</Typography.Title>
      <BarChart width={1040} height={300} data={data} margin={{ top: 5, right: 25, bottom: 5, left: 0 }}>
        <Bar dataKey="no_shows" fill="#ED9002" />
        <CartesianGrid stroke="#CCC5" strokeDasharray="2 2" />
        <XAxis dataKey="report_unit" />
        <YAxis />
        <Tooltip cursor={{fill: '#4749A022'}} />
      </BarChart>
      <Typography.Title level={4}>Production Report</Typography.Title>
      <BarChart width={1040} height={300} data={data} margin={{ top: 5, right: 25, bottom: 5, left: 45 }}>
        <Bar dataKey="hygienists_production" fill="#5E1AA1" />
        <Bar dataKey="dentists_production" fill="#A11D1A" />
        <Bar dataKey="total_production" fill="#ED9002" />
        <Bar dataKey="net_production" fill="#2696ED" />
        <CartesianGrid stroke="#CCC5" strokeDasharray="2 2" />
        <XAxis dataKey="report_unit" />
        <YAxis tickFormatter={(item) => formatter.format(item)} />
        <YAxis />
        <Tooltip cursor={{fill: '#4749A022'}} />
      </BarChart>
    </div>
  )
}

export default MetricsChart;