import { notification } from "antd";
import request from '../../config/request';
import history from "../../config/history";

import {
  USER_LOGIN_STARTED,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED, USER_LOGOUT_STARTED,
} from '../constants/users';

export const login = (data) => {
  return dispatch => {
    dispatch({ type: USER_LOGIN_STARTED });
    request.post('/api/login', data).then(response => {
      dispatch({ type: USER_LOGIN_SUCCESS, payload: { user: response.data, token: response.headers.authorization } });
      history.push("/dashboard");
    }).catch(error => {
      console.error(error);
      notification.error({ message: "Invalid username or password", description: "Please provide valid username/password" });
      dispatch({ type: USER_LOGIN_FAILED, payload: error.response?.data });
    });
  }
};

export const logout = () => {
  return dispatch => {
    dispatch({ type: USER_LOGOUT_STARTED });
    history.push("/login");
  }
}