import React from "react";
import {Col, Divider, Row, Select, Statistic, Tabs, Typography} from "antd";

const Navigation = ({ user, clinics, onClinicChange, missingCount, completedCount, onViewChange }) => {
  const handleChangeTab = (tab) => {
    onViewChange(tab);
  }

  const handleClinicChange = (val) => {
    onClinicChange(val);
  }

  return (
    <>
      <Row className="add-metric-navigation-banner">
        <Col>
          <Typography.Title>Hi, {user.first_name}</Typography.Title>
          <Typography.Text>Welcome back to your workspace!</Typography.Text>
        </Col>
        <Col>
          <Row>
            <Typography.Title level={5}>Monthly Status</Typography.Title>
          </Row>
          <Row>
            <Col>
              <Statistic title="Total Completed" value={completedCount} />
            </Col>
            <Col>
              <Statistic title="Total Missing" value={missingCount} />
            </Col>
          </Row>

        </Col>
      </Row>
      <Divider />
      <Row className="add-metric-navigation-filters">
        <Col>
          <Select onChange={handleClinicChange} size="large" allowClear>
            {clinics.map((clinic) => (
              <Select.Option value={clinic.id} key={clinic.id}>{clinic.name}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col className="filters-wrapper">
          <Row className="filters">
            <Col>Filter By:</Col>
            <Col>
              <Tabs defaultActiveKey="All" onChange={handleChangeTab}>
                <Tabs.TabPane tab="All" key="All" />
                <Tabs.TabPane tab="Missing" key="Missing" />
                <Tabs.TabPane tab="Completed" key="Completed" />
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Navigation;