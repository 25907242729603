import React from "react";
import {Comment, Popover, Table, Tag} from "antd";
import { BiCommentDetail } from "react-icons/all";

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const ClinicComment = ({ clinic, comment }) => (
  <Comment content={<p>{comment}</p>} author={<Tag>{clinic}</Tag>} />
)

const ClinicComments = ({ data }) => {
  const filteredComments = data.filter((row) => !!row[1])
  return (
    <>
      {filteredComments.length > 0 &&
      <Popover content={
        filteredComments.map((row) => (
          <ClinicComment key={row[0]} clinic={row[0]} comment={row[1]} />)
        )
      }>
        <BiCommentDetail size="2em" />
      </Popover>}
    </>
  )
}


const columns = [
  { title: 'Date', dataIndex: 'report_unit' },
  { title: 'Seen', dataIndex: 'seen', align: 'right' },
  { title: 'Scheduled', dataIndex: 'scheduled', align: 'right' },
  { title: 'New Patients', dataIndex: 'walk_ins', align: 'right' },
  { title: 'No Shows', dataIndex: 'no_shows', align: 'right' },
  { title: 'Hygiene Prod', dataIndex: 'hygienists_production', align: 'right', render: (hp) => formatter.format(hp) },
  { title: 'Dentist Prod', dataIndex: 'dentists_production', align: 'right', render: (dp) => formatter.format(dp) },
  { title: 'Total Prod', dataIndex: 'total_production', align: 'right', render: (tp) => formatter.format(tp) },
  { title: 'Net Prod', dataIndex: 'net_production', align: 'right', render: (tp) => formatter.format(tp) },
]

const columnsWithComments = [
  ...columns,
  { title: 'Comment', dataIndex: 'clinics_comments', align: 'center', render: (cc) => <ClinicComments data={cc} /> },
]

const MetricsTable = ({ data, group }) => {
  return (
    <div className="metrics-table">
      <Table columns={group === 'date' ? columnsWithComments : columns } dataSource={data} sticky pagination={false} rowKey="report_unit" />
    </div>
  )
}

export { columns, columnsWithComments };

export default MetricsTable;