import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import {Empty, List, Typography} from "antd";
import {If, Then} from "react-if";
import AddMetricDialog from "./AddMetricDialog";
import Navigation from "./Navigation";
import CompletedMetric from "./CompletedMetric";
import MissingMetric from "./MissingMetric";
import {addDailyReport, fetchDailyReport, setAddMetricModalOpen} from "../../redux/actions/addMetrics";
import {fetchClinics} from "../../redux/actions/clinics";

import "./AddMetrics.less";


const AddMetrics = ({ user, addDailyReport, fetchDailyReport, fetchClinics, clinics, missing, completed, addMetricModalOpen, setAddMetricModalOpen }) => {

  const [clinicId, setClinicId] = useState(null);
  const [currentAddMetricDate, setCurrentAddMetricDate] = useState(null);
  const [view, setView] = useState("All");

  const handleAddMetric = (date) => {
    setAddMetricModalOpen(true);
    setCurrentAddMetricDate(date)
  }

  const handleMarkAsHoliday = (date) => {
    const data = {
      daily_report: {
        report_date: date,
        holiday: true
      }
    }
    addDailyReport(clinicId, data);
  }

  const handleClose = () => {
    setAddMetricModalOpen(false);
  }

  const handleSubmit = (data) => {
    data['daily_report']['report_date'] = currentAddMetricDate;
    addDailyReport(clinicId, data);
  }

  const handleClinicChange = (clinicId) => {
    if(clinicId) {
      fetchDailyReport(clinicId);
    }
    setClinicId(clinicId);
  }

  const handleViewChange = (view) => {
    setView(view);
  }

  useEffect(() => {
    fetchClinics();
  }, []);



  return (
    <div className="add-metric-container">
      <Navigation user={user} clinics={clinics} onClinicChange={handleClinicChange} missingCount={missing.length} completedCount={completed.length} onViewChange={handleViewChange} />
      <AddMetricDialog date={currentAddMetricDate} isOpen={addMetricModalOpen} onClose={handleClose} onSubmit={handleSubmit} />
      <If condition={clinicId && (view === 'All' || view === 'Missing')}>
        <Then>
          <Typography.Title level={4}>Missing Metrics</Typography.Title>
          <List dataSource={missing} renderItem={(date) => (
            <MissingMetric key={date} date={date} onAdd={() => handleAddMetric(date)} onMarkAsHoliday={() => handleMarkAsHoliday(date)} />
          )} />
        </Then>
      </If>
      <If condition={clinicId && (view === 'All' || view === 'Completed')}>
        <Then>
          <Typography.Title level={4}>Completed Metrics</Typography.Title>
          <List dataSource={completed} renderItem={(data) => (
            <CompletedMetric key={data.report_date} data={data} />
          )} />
        </Then>
      </If>
      <If condition={!clinicId}>
        <Then>
          <Empty description="Please select a clinic from the dropdown"/>
        </Then>
      </If>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ...state.clinics, ...state.addMetrics, ...state.users };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDailyReport: (clinicId, data) => {
      dispatch(addDailyReport(clinicId, data));
    },
    fetchDailyReport: (clinicId) => {
      dispatch(fetchDailyReport(clinicId));
    },
    fetchClinics: () => {
      dispatch(fetchClinics());
    },
    setAddMetricModalOpen: (isOpen) => {
      dispatch(setAddMetricModalOpen(isOpen))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMetrics);
