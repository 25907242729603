import request from "../../config/request";

import {
  FETCHING_METRICS,
  FETCH_METRICS_SUCCESS,
  FETCH_METRICS_FAILED,
} from '../constants/viewMetrics';

export const fetchMetrics = (clinics, group, range) => {
  return dispatch => {
    dispatch({ type: FETCHING_METRICS });
    const data = { clinics, group, range };
    request.post('/api/reports', data).then(response => {
      dispatch({ type: FETCH_METRICS_SUCCESS, payload: response.data });
    }).catch(error => {
      console.error(error);
      dispatch({ type: FETCH_METRICS_FAILED, payload: error.response?.data });
    });
  }
}