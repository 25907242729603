import React from "react";
import {Card, Row, Col, Statistic, Badge} from "antd";
import {AiOutlineSchedule, BiDollarCircle, BsEye, BsHandThumbsDown, IoIosWalk} from "react-icons/all";

import CardTitle from "../../components/card-title/CardTitle";

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const CompletedCard = ({ report_date, seen, scheduled, walk_ins, no_shows, comments, hygienists_production, dentists_production, adjustment_production }) => (
  <Card className="completed-metric metric" title={<CardTitle date={report_date} />}>
    <Row justify="space-between">
      <Col className="item">
        <Statistic title="Seen" value={seen || '--'} prefix={<BsEye size={16} />} />
      </Col>
      <Col className="item">
        <Statistic title="Scheduled" value={scheduled || '--'} prefix={<AiOutlineSchedule size={16} />} />
      </Col>
      <Col className="item">
        <Statistic title="No Shows" value={no_shows || '--'} prefix={<BsHandThumbsDown size={18} />} />
      </Col>
      <Col className="item">
        <Statistic title="New Patients" value={walk_ins || '--'} prefix={<IoIosWalk size={16} />} />
      </Col>
      <Col className="item">
        <Statistic title="Hygiene Production" value={formatter.format(hygienists_production)} prefix={<BiDollarCircle size={18} />} />
      </Col>
      <Col className="item">
        <Statistic title="Dentist Production" value={formatter.format(dentists_production)} prefix={<BiDollarCircle size={18} />} />
      </Col>
      <Col className="item">
        <Statistic title="Adjustments" value={`(${formatter.format(adjustment_production)})`} prefix={<BiDollarCircle size={18} />} />
      </Col>
    </Row>
    <Row className="comments">
      <Col>
        <Statistic title="Comments" value={comments || '--'} />
      </Col>
    </Row>
  </Card>
)

const CompletedMetric = ({ data }) => {
  const {holiday, ...others} = data;
  return holiday ? <Badge.Ribbon text="Marked As Holiday"><CompletedCard {...others} /></Badge.Ribbon> : <CompletedCard {...others} />
}

export default CompletedMetric;