const USER_LOGIN_STARTED = 'USER_LOGIN_STARTED';
const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
const USER_LOGOUT_STARTED = 'USER_LOGOUT_STARTED';
const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export {
  USER_LOGIN_STARTED,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  USER_LOGOUT_STARTED,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILED,
}