import React from "react";
import moment from "moment";
import {GoCalendar} from "react-icons/all";

const CardTitle = ({ date }) => (
  <>
    <GoCalendar />
    <span>{moment(date).format('dddd MMMM D, YYYY')}</span>
  </>
)

export default CardTitle;