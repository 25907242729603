import React from "react";
import {Card, Row, Col, Statistic, Badge} from "antd";
import { MdOutlinePersonalInjury, RiStethoscopeFill } from "react-icons/all";

import CardTitle from "../../components/card-title/CardTitle";

const CompletedCard = ({ check_in_date, no_of_dentists, no_of_hygienists, no_of_office_team_members }) => (
  <Card className="completed-check-in check-in" title={<CardTitle date={check_in_date} />}>
    <Row justify="space-around">
      <Col className="item">
        <Statistic title="No. of Dentists" value={no_of_dentists || '--'} prefix={<RiStethoscopeFill size={16} />} />
      </Col>
      <Col className="item">
        <Statistic title="No. of Hygienists" value={no_of_hygienists || '--'} prefix={<RiStethoscopeFill size={16} />} />
      </Col>
      <Col className="item">
        <Statistic title="No. of Office Team Members" value={no_of_office_team_members || '--'} prefix={<MdOutlinePersonalInjury size={16} />} />
      </Col>
    </Row>
  </Card>
)

const CompletedMetric = ({ data }) => {
  const { holiday, ...others } = data;
  return holiday ? <Badge.Ribbon text="Marked As Holiday"><CompletedCard {...others} /></Badge.Ribbon> : <CompletedCard {...others} />
}

export default CompletedMetric;