import request from "../../config/request";

import {
  ADDING_DAILY_REPORT_STARTED,
  ADDING_DAILY_REPORT_SUCCESS,
  ADDING_DAILY_REPORT_FAILED,
  FETCH_DAILY_REPORT_STARTED,
  FETCH_DAILY_REPORT_SUCCESS,
  FETCH_DAILY_REPORT_FAILED,
  ADD_METRIC_MODAL_OPEN,
  ADD_METRIC_MODAL_CLOSE,
} from '../constants/addMetrics';

export const addDailyReport = (clinicId, data) => {
  return dispatch => {
    dispatch({ type: ADDING_DAILY_REPORT_STARTED });
    request.post(`/api/clinics/${clinicId}/daily_reports`, data).then(response => {
      dispatch({ type: ADDING_DAILY_REPORT_SUCCESS, payload: response.data });
      dispatch({ type: ADD_METRIC_MODAL_CLOSE });
      dispatch(fetchDailyReport(clinicId));
    }).catch(error => {
      console.error(error);
      dispatch({ type: ADDING_DAILY_REPORT_FAILED, payload: error.response?.data });
    });
  }
}

export const fetchDailyReport = (clinicId) => {
  return dispatch => {
    dispatch({ type: FETCH_DAILY_REPORT_STARTED });
    request.get(`/api/clinics/${clinicId}/daily_reports`).then(response => {
      dispatch({ type: FETCH_DAILY_REPORT_SUCCESS, payload: response.data });
    }).catch(error => {
      console.error(error);
      dispatch({ type: FETCH_DAILY_REPORT_FAILED, payload: error.response?.data });
    });
  }
}

export const setAddMetricModalOpen = (isOpen) => {
  return dispatch => {
    dispatch({type: isOpen ? ADD_METRIC_MODAL_OPEN : ADD_METRIC_MODAL_CLOSE });
  }
}