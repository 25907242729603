import React from "react";
import {Button, Card, Col, Popconfirm, Row, Space, Statistic} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {MdCelebration, MdOutlinePersonalInjury, RiStethoscopeFill} from "react-icons/all";
import CardTitle from "../../components/card-title";


const AddMetricButton = ({ onAdd, onMarkAsHoliday }) => (
  <Space>
    <Button type="primary" icon={<PlusOutlined />} onClick={onAdd}>Add</Button>
    <Popconfirm title="Are you sure to mark this day as holiday?" onConfirm={onMarkAsHoliday} okText="Yes" cancelText="No">
      <Button className="mark-as-holiday" icon={<MdCelebration size={16}/>}>Mark As Holiday</Button>
    </Popconfirm>
  </Space>
)

const MissingMetric = ({ date, onAdd, onMarkAsHoliday }) => {
  return (
    <Card className="missing-check-in check-in" title={<CardTitle date={date} />} extra={<AddMetricButton onAdd={onAdd} onMarkAsHoliday={onMarkAsHoliday} />}>
      <Row justify="space-around">
        <Col className="item">
          <Statistic title="No. of Dentists" value="--" prefix={<RiStethoscopeFill size={16} />} />
        </Col>
        <Col className="item">
          <Statistic title="No. of Hygienists" value="--" prefix={<RiStethoscopeFill size={16} />} />
        </Col>
        <Col className="item">
          <Statistic title="No. of Office Team Members" value="--" prefix={<MdOutlinePersonalInjury size={16} />} />
        </Col>
      </Row>
    </Card>
  )
}

export default MissingMetric;