const ADDING_DAILY_REPORT_STARTED = 'ADDING_DAILY_REPORT_STARTED';
const ADDING_DAILY_REPORT_SUCCESS = 'ADDING_DAILY_REPORT_SUCCESS';
const ADDING_DAILY_REPORT_FAILED = 'ADDING_DAILY_REPORT_FAILED';
const FETCH_DAILY_REPORT_STARTED = 'FETCH_DAILY_REPORT_STARTED';
const FETCH_DAILY_REPORT_SUCCESS = 'FETCH_DAILY_REPORT_SUCCESS';
const FETCH_DAILY_REPORT_FAILED = 'FETCH_DAILY_REPORT_FAILED';
const ADD_METRIC_MODAL_OPEN = 'ADD_METRIC_MODAL_OPEN';
const ADD_METRIC_MODAL_CLOSE = 'ADD_METRIC_MODAL_CLOSE';


export {
  ADDING_DAILY_REPORT_STARTED,
  ADDING_DAILY_REPORT_SUCCESS,
  ADDING_DAILY_REPORT_FAILED,
  FETCH_DAILY_REPORT_STARTED,
  FETCH_DAILY_REPORT_SUCCESS,
  FETCH_DAILY_REPORT_FAILED,
  ADD_METRIC_MODAL_OPEN,
  ADD_METRIC_MODAL_CLOSE,
}