import React from "react";
import {Col, Radio, Row, Tabs, TreeSelect} from "antd";
import {BarChartOutlined, TableOutlined} from "@ant-design/icons";

const Navigation = ({ onGroupChange, onViewChange, onClinicsChange, clinics, regions }) => {
  const handleTabChange = (tab) => {
    onGroupChange(tab);
  }

  const handleViewChange = (e) => {
    onViewChange(e.target.value);
  }

  const handleClinicChange = (selected) => {
    onClinicsChange(selected);
  }

  return (
    <>
      <Row className="view-metric-navigation-filters">
        <Col>
          <TreeSelect onChange={handleClinicChange} size="large"
                      multiple treeCheckable allowClear
                      placeholder="Select Clinic(s)" maxTagCount={1}
                      treeDefaultExpandAll
          >
            {regions.map((r) => (
              <TreeSelect.TreeNode key={r.id} value={r.id} title={r.name}>
                {clinics.filter((c) => c.region_id === r.id).map((c) => (
                  <TreeSelect.TreeNode key={c.id} value={c.id} title={c.name} />
                ))}
              </TreeSelect.TreeNode>
            ))}
          </TreeSelect>
        </Col>
        <Col className="filters-wrapper">
          <Row className="filters">
            <Col>Filter By:</Col>
            <Col>
              <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                <Tabs.TabPane tab="Daily" key="date" />
                <Tabs.TabPane tab="Weekly" key="week" />
                <Tabs.TabPane tab="Monthly" key="month" />
                <Tabs.TabPane tab="Yearly" key="year" />
              </Tabs>
            </Col>
          </Row>
        </Col>
        <Col className="view-type-wrapper">
          <Radio.Group defaultValue="table" buttonStyle="solid" onChange={handleViewChange}>
            <Radio.Button value="table"><TableOutlined /></Radio.Button>
            <Radio.Button value="chart"><BarChartOutlined /></Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
    </>
  )
}

export default Navigation;