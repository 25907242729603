import request from "../../config/request";

import {
  FETCHING_CLINICS,
  FETCH_CLINICS_SUCCESS,
  FETCH_CLINICS_FAILED,
} from '../constants/clinics';

export const fetchClinics = () => {
  return dispatch => {
    dispatch({ type: FETCHING_CLINICS });
    request.get('/api/clinics').then(response => {
      dispatch({ type: FETCH_CLINICS_SUCCESS, payload: response.data });
    }).catch(error => {
      console.error(error);
      dispatch({ type: FETCH_CLINICS_FAILED, payload: error.response?.data });
    });
  }
}