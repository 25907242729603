import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {Divider, Empty} from "antd";

import Navigation from "./Navigation";
import DateFilter from "./DateFilter";
import MetricsTable, { columns } from "./MetricsTable";
import MetricsChart from "./MetricsChart";

import {fetchMetrics} from "../../redux/actions/viewMetrics";
import {fetchClinics} from "../../redux/actions/clinics";

import "./ViewMetrics.less";


const ViewMetrics = ({ loading, data, fetchMetrics, fetchClinics, clinics, regions }) => {
  const [group, setGroup] = useState('date');
  const [view, setView] = useState('table');
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [range, setRange] = useState([]);

  const handleFilterChange = (r) => {
    setRange(r);
    fetchMetrics(selectedClinics, group, r);
  }

  const handleGroupChange = (g) => {
    setGroup(g);
    setRange([]);
  }

  const handleViewChange = (v) => {
    setView(v);
  }

  const handleClinicsChange = (c) => {
    setSelectedClinics(c);
    if(range.length === 2) { fetchMetrics(c, group, range); }
  }

  const handleExport = () => {
    const title = columns.map((column) => column.title ).join(",");
    const rows = data.map((row) => columns.map(column => row[column.dataIndex]).join(",")).join("\n");
    const content = encodeURI(`data:text/csv;charset=utf-8,${title}\n${rows}`);
    const link = document.createElement("a");
    link.setAttribute("href", content);
    link.setAttribute("download", "export.csv");
    document.body.appendChild(link);
    link.click();
  }

  useEffect(() => {
    fetchClinics();
  }, []);


  let missing = [];
  if(selectedClinics.length === 0){ missing.push('clinics') }
  if(range.length === 0 || range.filter((d) => !!d).length === 0){ missing.push('date range') }
  const exportEnabled = missing.length === 0 && view === 'table';
  return (
    <div className="view-metric-container">
      <Navigation onGroupChange={handleGroupChange} onViewChange={handleViewChange} onClinicsChange={handleClinicsChange} clinics={clinics} regions={regions} />
      <Divider />
      <DateFilter group={group} exportEnabled={exportEnabled} onChange={handleFilterChange} onExport={handleExport} />
      { missing.length > 0 && <Empty className="view-metric-empty" description={`Please provide ${missing.join(", ")}`}/> }
      { missing.length === 0 && view === 'table' && <MetricsTable group={group} data={data} /> }
      { missing.length === 0 && view === 'chart' && <MetricsChart group={group} data={data} /> }
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ...state.viewMetrics, ...state.clinics };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMetrics: (clinics, group, range) => {
      dispatch(fetchMetrics(clinics, group, range))
    },
    fetchClinics: () => {
      dispatch(fetchClinics());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewMetrics);
